<template>
  <v-footer padless color="white">
    <v-row justify="center" no-gutters>
      <v-col class="py-4 text-center black--text" cols="12"
        >© {{ new Date().getFullYear() }} Lin Yuhong &nbsp;
        <a class="text-center black--text" href="https://beian.miit.gov.cn/">{{
          icp_record
        }}</a></v-col
      >
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      icp_record: "",
    };
  },
  mounted() {
    this.$root.$on("msg", (msg) => {
      this.icp_record = msg;
    });
  },
};
</script>
<style></style>
