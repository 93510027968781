<template>
  <v-app app>
    <TopBar></TopBar>
    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <Nav class="nav"></Nav>
          </v-col>
          <v-col cols="12" md="9">
            <v-sheet min-height="80vh" rounded="lg">
              <router-view :key="$route.path"></router-view>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";

export default {
  name: "Home",
  components: {TopBar, Footer, Nav},
};
</script>

<style>
.nav {
  position: sticky;
  top: 70px;
}
</style>
