<template>
  <v-card class="mx-auto" max-width="300">
    <v-img :src="profileInfo.img">
      <v-card-title>
        <v-col align="center">
          <v-avatar size="130" color="grey">
            <img :src="profileInfo.avatar"/>
          </v-avatar>
          <div class="mt-3">{{ profileInfo.name }}</div>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
    </v-img>
    <v-card-title>关于我:</v-card-title>
    <v-card-text>{{ profileInfo.description }}</v-card-text>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item>
        <v-btn class="ma-2" icon :href="profileInfo.github">
          <v-icon>mdi-github</v-icon>
        </v-btn>
        <v-btn class="ma-2" icon :href="profileInfo.email">
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-btn class="ma-2" icon :href="profileInfo.bili">
          <v-icon>mdi-television-classic</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      profileInfo: {
        id: 0,
      },
    };
  },
  created() {
    this.getProfileInfo();
  },
  methods: {
    // 获取个人设置
    async getProfileInfo() {
      const {data: res} = await this.$http.get(
          `profile/${this.profileInfo.id}`
      );
      this.profileInfo = res.data;
      this.$root.$emit("msg", res.data.icp_record);
    },
  },
};
</script>
<style>

</style>
