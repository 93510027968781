<template>
  <div>
    <v-app-bar mobileBreakpoint="sm" app dark flat color="primary">
      <v-app-bar-nav-icon
          dark
          class="hidden-md-and-up"
          @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-tabs dark center-active centered class="hidden-sm-and-down">
        <v-tab @click="$router.push('/')">首页</v-tab>
        <v-tab
            v-for="item in categoryList"
            :key="item.id"
            text
            @click="goCategory(item.id)"
            class="hidden-sm-and-down"
        >{{ item.name }}
        </v-tab
        >
      </v-tabs>

      <v-spacer></v-spacer>
      <!--登录-->
      <v-dialog max-width="800">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!headers.username" text dark v-bind="attrs" v-on="on">请登录</v-btn>

          <v-btn v-if="headers.username" text dark>欢迎你{{ headers.username }}</v-btn>
          <v-btn class="hidden-md-and-down" v-if="headers.username" text dark @click="loginOut">退出</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>请登录</v-toolbar>
            <v-form ref="loginFormRef" v-model="valid">
              <v-card-text class="mt-5">
                <v-text-field
                    v-model="formData.username"
                    hint="至少4个字符"
                    counter="12"
                    :rules="nameRules"
                    label="请输入用户名"
                ></v-text-field>
                <v-text-field
                    v-model="formData.password"
                    hint="至少6个字符"
                    counter="20"
                    :rules="passwordRules"
                    label="请输入密码"
                    type="password"
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="login">确定</v-btn>
                <v-btn text @click="dialog.value = false">取消</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
      <!--注册-->
      <v-dialog max-width="800">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!headers.username" text dark v-bind="attrs" v-on="on">注册</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-form ref="registerFormRef" v-model="registerFormValid">
            <v-card>
              <v-toolbar color="primary" dark>欢迎注册</v-toolbar>
              <v-card-text class="mt-5">
                <v-text-field
                    v-model="formData.username"
                    hint="至少4个字符"
                    counter="12"
                    :rules="nameRules"
                    label="请输入用户名"
                ></v-text-field>
                <v-text-field
                    v-model="formData.password"
                    :rules="passwordRules"
                    hint="至少6个字符"
                    counter="20"
                    label="请输入密码"
                    type="password"
                ></v-text-field>
                <v-text-field
                    v-model="checkPassword"
                    :rules="checkPasswordRules"
                    hint="至少6个字符"
                    counter="20"
                    label="请确认密码"
                    type="password"
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="registerUser">确定</v-btn>
                <v-btn text @click="dialog.value = false">取消</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>

    </v-app-bar>
    <v-navigation-drawer v-model="drawer" color="primary" dark app temporary>
      <v-list>
        <v-list-item-title>
          <v-btn href="/" dark text>
            <v-icon small>mdi-home</v-icon>
            首页
          </v-btn>
        </v-list-item-title>
        <v-list-item-group v-model="group">
          <v-list-item
              v-for="item in categoryList"
              :key="item.id"
          >
            <v-list-item-content>
              <v-btn dark text @click="goCategory(item.id)" color="white">{{
                  item.name
                }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoryList: [],
      drawer: false,
      group: null,
      valid: true,
      registerFormValid: true,
      searchName: '',
      formData: {
        username: '',
        password: ''
      },
      checkPassword: '',
      dialog: false,
      headers: {
        Authorization: '',
        username: ''
      },
      nameRules: [
        (v) => !!v || '用户名不能为空',
        (v) =>
            (v && v.length >= 4 && v.length <= 12) ||
            '用户名必须在4到12个字符之间'
      ],
      passwordRules: [
        (v) => !!v || '密码不能为空',
        (v) =>
            (v && v.length >= 6 && v.length <= 20) || '密码必须在6到20个字符之间'
      ],
      checkPasswordRules: [
        (v) => !!v || '密码不能为空',
        (v) =>
            (v && v.length >= 6 && v.length <= 20) || '密码必须在6到20个字符之间',
        (v) => v === this.formData.password || '密码两次输入不一致，请检查'
      ]
    };
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  created() {
    this.GetCategoryList()
  },
  methods: {
    // 获取分类
    async GetCategoryList() {
      const {data: res} = await this.$http.get("category")
      this.categoryList = res.data
    },

    // 跳转到分类
    goCategory(cid) {
      this.$router.push(`/category/${cid}`)
    },

    // 登录
    async login() {
      if (!this.$refs.loginFormRef.validate()) {
        return this.$message.error("请检查输入的用户名和密码!")
      }
      const {data: res} = await this.$http.post("front/login", this.formData)
      console.log(res)
      if (res.status !== 200) return this.$message.error(res.message)
      localStorage.setItem("username", res.data)
      localStorage.setItem("user_id", res.id)
      this.$message.success("登录成功")
      this.$router.go(0)
    },

    // 注销
    loginOut() {
      localStorage.removeItem("token")
      localStorage.removeItem("username")
      this.$message.success('退出成功')
      this.$router.go(0)
    },

    // 注册
    async registerUser() {
      if (!this.$refs.registerFormRef.validate()) {
        return this.$message.error("请检查输入的用户名和密码")
      }
      const {data: res} = await this.$http.post("user/add", {
        username: this.formData.username,
        password: this.formData.password,
        role: 2
      })
      if (res.status !== 200) return this.$message.error(res.message)
      this.$message.success("注册成功")
      this.$router.go(0)
    }
  },
  mounted() {
    this.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      username: localStorage.getItem("username")
    }
  },
};
</script>
<style></style>
