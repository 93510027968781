import Vue from 'vue'
import axios from 'axios'

let Url = 'https://nightwind.top/api/v1/' // 线上
// let Url = 'http://localhost:3000/api/v1/' // 调试

axios.defaults.baseURL = Url
Vue.prototype.$http = axios

export { Url }
