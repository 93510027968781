<template>
  <Home></Home>
</template>

<script>
import Home from "./views/Home.vue";
export default {
  name: "App",
  data: () => ({
    //
  }),
  components: { Home },
};
</script>
